<template>
  <v-card
    elevation="0"
    :class="['scrollbar', showCloseButton ? 'popup' : 'pa-1']"
    style="height: 100%; overflow-y: scroll"
  >
    <div style="width: 100%" class="d-flex align-center">
      <v-col class="pa-1">
        <v-card
          class="pa-3 d-flex align-center"
          elevation="0"
          style="height: 100%; border: 1px solid #b0bec5"
        >
          <div class="fontBlack text-h6">Target: {{ scanUrl }}</div>
        </v-card>
      </v-col>
      <v-col class="pa-1">
        <v-card
          elevation="0"
          style="height: 100%; border: 1px solid #b0bec5"
          class="pa-3 d-flex align-center"
        >
          <div class="fontBlack text-h6">Time Taken:</div>
          <div
            :timeTaken="timeTaken"
            class="fontBlack text-h6"
            style="margin-left: 15px"
          >
            {{ timeTaken }}
          </div>
        </v-card>
      </v-col>
    </div>

    <v-row style="margin-top: 10px">
      <v-col cols="8">
        <horizontal-chart :vulnLevel1="vulnLevel1"></horizontal-chart>
      </v-col>
      <v-col cols="4" style="padding: 0px 50px; padding-left: 0px; display: flex; justify-content: center; align-items: center;">
        <v-card
          elevation="1"
          outlined
          color="blue-grey lighten-5"
          style="padding: 20px; width: 100%;"
        >
          <div class="text-h5 font-weight-bold" style="margin-bottom: 5px">
            TOTAL {{ findings }}
          </div>
          <div
            v-for="(vuln, idx) in vulnLevel1"
            :key="idx"
            style="
              display: flex;
              justify-content: start;
              align-items: center;
              width: 100%;
              position: relative;
              margin-bottom: 5px;
            "
          >
            <v-sheet :color="vuln.color" width="15" height="15"></v-sheet>
            <div
              class="text-subtitle-1 font-weight-medium"
              style="margin-left: 10px"
            >
              {{ vuln.level }}
            </div>
            <div
              class="text-h5 font-weight-bold"
              style="position: absolute; right: 0px"
            >
              {{ vuln.value }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-card-text>
      <div class="fontBlack text-h6 font-weight-regular">
        Vulnerabilities Found
      </div>
      <div style="width: 100%">
        <v-card v-if="resultData.length == 0">
          <v-card-text>There's no Vulnerabilities</v-card-text>
        </v-card>
        <div v-if="resultData.length">
          <v-expansion-panels
            v-for="(data, idx) in resultData"
            :key="idx"
            multiple
          >
            <v-expansion-panel v-for="(item, i) in data" :key="i">
              <v-expansion-panel-header style="padding: 0px !important">
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td
                        class="text-left"
                        style="min-width: 130px; width: 130px"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: left;
                            align-items: center;
                          "
                        >
                          <div
                            :class="item.risk"
                            style="
                              width: 12px;
                              height: 12px;
                              border-radius: 15px;
                              margin-right: 5px;
                            "
                          ></div>
                          {{ changeName(item.risk) }}
                        </div>
                      </td>
                      <td
                        class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-if="item.risk !== 'Malware' && item.risk !== 'Yara'"
                        v-html="item.alert"
                      ></td>
                      <td
                        v-if="item.risk === 'Malware'"
                        class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-html="
                          `Malware was found in ${malwareCnt} of ${item.cnt} resources`
                        "
                      ></td>
                      <td
                        v-if="item.risk === 'Yara'"
                        class="text-left"
                        style="min-width: max-content; width: 300px"
                        v-html="
                          `Yara was found in ${cryptoCnt} of ${item.cnt} resources`
                        "
                      ></td>
                      <td class="text-left tdWidth">Instances</td>
                      <td
                        v-if="item.risk !== 'Malware' && item.risk !== 'Yara'"
                        class="text-left"
                        style="min-width: max-content; width: 50px"
                      >
                        {{ item.cnt }}
                      </td>
                      <td
                        v-if="item.risk === 'Malware'"
                        class="text-left"
                        style="min-width: max-content; width: 50px"
                      >
                        {{ malwareCnt }}
                      </td>
                      <td
                        v-if="item.risk === 'Yara'"
                        class="text-left"
                        style="min-width: max-content; width: 50px"
                      >
                        {{ cryptoCnt }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <template v-if="item.risk.toLowerCase() === 'malware'">
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance.slice().sort((a, b) => {
                      const descA =
                        a.description !== 'no search malware at Url\n' &&
                        a.description !== 'Connection failed please check url\n' &&
                        a.description !== '' &&
                        a.description !== null;
                      const descB =
                        b.description !== 'no search malware at Url\n' &&
                        b.description !== 'Connection failed please check url\n' &&
                        b.description !== '' &&
                        b.description !== null;
                      return descA === descB ? 0 : descA ? -1 : 1;
                    })"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          v-if="
                            inst.description !== 'no search malware at Url\n' &&
                            inst.description !== 'Connection failed please check url\n' &&
                            inst.description !== '' &&
                            inst.description !== null
                          "
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td
                          v-if="
                            inst.description === 'no search malware at Url\n' ||
                            inst.description === 'Connection failed please check url\n' ||
                            inst.description === '' ||
                            inst.description === null
                          "
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        ></td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ inst.description }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-else-if="item.risk === 'Yara'">
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance.slice().sort((a, b) => {
                      const descA =
                        a.description !== 'It does not match the yara rule'
                      const descB =
                        b.description !== 'It does not match the yara rule'
                      return descA === descB ? 0 : descA ? -1 : 1;
                    })"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                    <tbody
                      v-if="
                        inst.description !== 'It does not match the yara rule'
                      "
                    >
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ cryptoDesc(inst.description) }}</td>
                      </tr>
                    </tbody>
                    <tbody
                      v-if="
                          inst.description === 'It does not match the yara rule'
                      "
                    >
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        ></td>
                          <td class="text-left" style="width: 200px">URL</td>
                          <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">DESCRIPTION</td>
                        <td class="text-left">{{ inst.description }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>

                <template v-else>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <td class="text-left">Description</td>
                        <td style="text-align: justify">
                          {{ item.description }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-left">Solution</td>
                        <td style="text-align: justify">{{ item.solution }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Other information</td>
                        <td class="text-left">{{ item.other }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">References</td>
                        <td class="text-left">{{ item.reference }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">CWE ID</td>
                        <td class="text-left" v-html="item.cweid"></td>
                      </tr>
                      <tr>
                        <td class="text-left">WASC ID</td>
                        <td class="text-left" v-html="item.wascid"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-simple-table
                    dense
                    v-for="(inst, k) in item.instance"
                    :key="k"
                    style="
                      border-top: thin solid rgba(0, 0, 0, 0.12);
                      border-radius: 0px;
                    "
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="3"
                          class="text-center"
                          style="border: transparent; width: 100px"
                        >
                          Instance
                        </td>
                        <td class="text-left" style="width: 200px">URL</td>
                        <td class="text-left">{{ inst.url }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">METHOD</td>
                        <td class="text-left">{{ inst.method }}</td>
                      </tr>
                      <tr>
                        <td class="text-left">Param</td>
                        <td class="text-left">{{ inst.param }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card-text>

    <v-btn
      v-if="showCloseButton"
      icon
      @click="$emit('close')"
      style="position: absolute; top: 5px; right: 10px"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import horizontalChart from "./horizontalChart.vue";

export default {
  components: {
    horizontalChart,
  },
  name: "scanResult",
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isHistoryPage: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => [],
    },
    timeTaken: {
      type: String,
      default: "0",
    },
    timelines: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    resultData: [],
    malwareCnt: 0,
    allMalwares: 0,
    cryptoCnt: 0,
    allCrypto: 0,
    totalFindgins: 0,
  }),
  computed: {
    scanUrl() {
      const fullUrl = this.result[0]?.url || "N/A";
      try {
        const urlObj = new URL(fullUrl);
        return `${urlObj.protocol}//${urlObj.host}`;
      } catch (e) {
        console.warn("Invalid URL:", fullUrl);
        return "N/A";
      }
    },
    findings() {
    let total = 0;
    
    const levels = ["High", "Medium", "Low", "Informational", "Malware", "Yara"];
    
    levels.forEach((level) => {
      if (level === "Malware") {
        total += this.result
          .filter(item => item.risk && item.risk.toLowerCase() === "malware")
          .reduce((acc, item) => {
            const validInstances = item.instance?.filter(inst => 
              inst.description && 
              inst.description !== "no search malware at Url\n" &&
              inst.description !== "Connection failed please check url\n" &&
              inst.description !== "" && 
              inst.description !== null
            ) || [];
            this.allMalwares++;
            return acc + validInstances.length;
          }, 0);
          console.log(total);
      } else if(level === "Yara"){
        var cryptoArr=this.result.filter(item=> item.risk==="Yara");
        this.allCrypto=cryptoArr.length;
        this.cryptoCnt=cryptoArr.filter(inst => inst.description !== "It does not match the yara rule").length;
        total+=this.cryptoCnt;
      } else {
        total += this.result.filter(item => 
          item.risk && item.risk.toLowerCase() === level.toLowerCase()
        ).length;
      }
    });
    
    return total > 0 ? total : 0;
  },
  vulnLevel1() {
    const levels = ["High", "Medium", "Low", "Informational", "Malware", "Yara"];
    const result = Array.isArray(this.result) ? this.result : [];

    return levels.map(level => {
      let count;
      if (level === "Malware") {
        count = result
          .filter(item => item.risk && item.risk.toLowerCase() === "malware")
          .reduce((acc, item) => {
            const validInstances = item.instance?.filter(inst => 
              inst.description &&
              inst.description !== "no search malware at Url\n" &&
              inst.description !== "Connection failed please check url\n" &&
              inst.description !== "" &&
              inst.description !== null
            ) || [];
            this.malwareCnt = acc + validInstances.length;
            return acc + validInstances.length;
          }, 0);
      } else if(level === "Yara"){
        count=result.filter(item=> item.risk==="Yara")
        .filter(inst => inst.description !== "It does not match the yara rule").length;

      } else {
        count = result.filter(item => 
          item.risk && item.risk.toLowerCase() === level.toLowerCase()
        ).length;
      }
      if(level === "Informational"){
        level = "Information"
      }
      return {
        level,
        value: count,
        color: this.getColor(level),
      };
    });
  },
  },
  methods: {
    cryptoDesc:function(desc){
      console.log(desc);
      var desc1= desc.replace(/'/g, '"');
      desc1=desc1.replace(/(\[|\s)([A-Za-z_][A-Za-z0-9_]*)/g, '$1"$2"');

      const descArr= JSON.parse(desc1);
      // match_list index 확인
      console.log(descArr);
      var matchArr=descArr.map(i => i.match_list[0]);
      var str='';
      matchArr.forEach((x)=>{
        str+=x+'\n';
      })
      return str;
    },
    getColor(level) {
      switch (level) {
        case "High":
          return "#FF5252";
        case "Medium":
          return "#FFAB40";
        case "Low":
          return "#FFF176";
        case "Information":
          return "#82B1FF";
        case "Malware":
          return "#BDBDBD";
        case "Yara":
          return "#8D6E63"
        default:
          return "#FFFFFF";
      }
    },
    changeName(name) {
      const newName = name === "Informational" ? "Information" :name;
      return newName.charAt(0).toUpperCase() + newName.slice(1);
    },
  },
  watch: {
    result: {
      handler(newVal) {
        if (!Array.isArray(newVal)) {
          console.error("newVal is not an array:", newVal);
          return;
        }
        // console.log("result newVal");
        // console.log(newVal);
        this.resultData = [];
        this.malwareCnt = 0;
        this.cryptoCnt = 0;
        this.allCrypto = 0;
        const levels = ["High", "Medium", "Low", "Informational", "Malware", "Yara"];
        // risk 별로 분류
        const dataArr = levels.map((level) =>
          Array.isArray(newVal)
            ? newVal.filter(
                (item) =>
                  item.risk && item.risk.toLowerCase() === level.toLowerCase()
              )
            : []
        );

        console.log("dataArr")
        console.log(dataArr)

        dataArr.forEach((group) => {
          if (group.length) {// 해당 risk 발견된 경우
            const uniqueAlerts = [];
            const aggregatedData = [];
            console.log(group);

            group.forEach((item) => {
              const idx = uniqueAlerts.indexOf(item.alert);
              if (idx === -1) {// 새로운 alert 값인 경우
                uniqueAlerts.push(item.alert);
                item.cnt = group.filter((i) => i.alert === item.alert).length;
                item.instance = item.instance || [
                  {
                    url: item.url,
                    method: item.method || "N/A", 
                    param: item.param || "N/A",
                    description: item.description || "N/A",
                  },
                ];
                aggregatedData.push(item);
              } else {
                aggregatedData[idx].instance.push({
                  url: item.url || "N/A",
                  method: item.method || "N/A",
                  param: item.param || "N/A",
                  description: item.description || "N/A",
                });
                
              }
              // console.log("aggregatedData");
              // console.log(aggregatedData);
              console.log("Risk level:", item.risk);
              if (item.risk.toLowerCase() === "malware") {
                console.log("Found malware item:", item);
                if (Array.isArray(item.instance)) {
                  const validInstances = item.instance.filter((inst) => {
                    return (
                      inst.description &&
                      inst.description !== 'Connection failed please check url\n' &&
                      inst.description !== null
                    );
                  });
                  console.log("Valid inst ")
                  validInstances.map((inst, i) =>(
                    console.log(inst + i)
                  ))
                  this.malwareCnt = validInstances.length;
                  console.log("MALWARE CNT " + this.malwareCnt)
                }
              }
              if(item.risk === "Yara"){
                console.log("Yara");
                console.log(item);
                if (Array.isArray(item.instance)) {
                  this.allCrypto=item.instance.length;
                  this.cryptoCnt=item.instance.filter(item => item.description !== "It does not match the yara rule").length;
                  
                }
              }
            });
            this.resultData.push(aggregatedData);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.v-dialog__content,
.v-dialog::-webkit-scrollbar {
  scrollbar-width: none;
  background: transparent;
}

.scrollbar {
  scrollbar-width: none;
  background: transparent;
  width: 100vw !important;
  padding-left: 0px !important;
}

.tdBorder {
  border-radius: 10px;
}

.High {
  background-color: #ff5252;
}
.Medium {
  background-color: #ffab40;
}
.Low {
  background-color: #fff176;
}
.Informational {
  background-color: #82b1ff;
}
.Malware {
  background-color: #bdbdbd;
}
.Yara {
  background-color: #8D6E63;
}
.popup {
  padding: 15px !important;
  padding-top: 45px !important;
}
</style>
