<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      hide-overlay
      absolute
      height="100%"
      mobile-breakpoint=""
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <iframe style="min-width: 220px;" width="100%" height="210" src="https://www.youtube.com/embed/XrpjJBbNigc?si=qWuJtNWHyJvX7Bv2" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in filteredItems"
          :key="item.title"
          :to="item.href"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isLogged" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>LOGOUT</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div style="position: absolute; bottom: 0px; width: 100%;">
        <v-divider></v-divider>
        <v-card-title 
          style="min-height: 30px !important; padding: 0px !important;justify-content: center !important; margin-top: 10px;"
        >
          <a 
            v-for="(icon,index) in icons"
            :href="icon.link"
            v-bind:key="index"
            target="_blank"
            style="width: 50px; display: flex; text-decoration: none; justify-content: center; align-items: center;"
            class="snsBtn"
          >
            <v-hover v-slot="{hover}">
              <v-btn
                class="mx-4"
                icon
                :color="hover ? icon.color : 'rgba(0,0,0,.54)'"
              >
                <v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-hover>
          </a>
        </v-card-title>
        <div class="pa-0 text-center">info@wikisecurity.net</div>
        <div class="pa-1 text-center text-caption">Don't Miss To Follow Us On Our Social Networks Official Accounts.</div>
      </div>
    </v-navigation-drawer>
    
    <v-app-bar
      app
      color="blue-grey lighten-4"
      dense
      elevation="3" 
      light
      id="main-bar"
    >
    <v-tab
    class="home-btn"
    :to="'/'"
    type="button"
    >
      <v-img
          class="mb-1;"
          max-width="69"
          contain
          src="./assets/logo.png"
          style="cursor: pointer;"
        >
      </v-img>
    </v-tab>
      <div class="px-3" style="font-size: 20px;">Web Vulnerability Scanner</div>
      <div v-if="isLogged" style="height: 100%; position: absolute; right: 10px; display: flex; align-items: center; justify-content: center;">
        <div style="text-align: right; margin-right: 5px;">
          <div style="font-size: 18px; font-weight: bold; height: 20px;">
            {{ userData.username }}
          </div>
        </div>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: 30px; height: 30px;">
                <img src="@/assets/account.svg" v-bind="attrs" v-on="on" />
              </div>
            </template>

            <v-list id="userMenu" class="pa-1">
              <v-card-text v-if="userData.lastLogin!=null" class="text-subtitle-3" style="padding: 10px; font-size: 13px; line-height: 15px;">
                Last Login<br>: {{ userData.lastLogin }}
              </v-card-text>
              <v-card-text v-if="userData.lastLogin==null" class="text-subtitle-3" style="padding: 10px; font-size: 13px; line-height: 15px;">
                First Login
              </v-card-text>
              <!-- <v-card-text  style="padding: 10px; font-size: 13px; line-height: 15px;">
                Validation<br>: {{ userData.check }}
              </v-card-text> -->
              <v-divider></v-divider>
              <v-list-item :to="'/settings'" @click="show=false">
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
// import { address } from './mixins/api';

export default {
  name: 'App',
  data: () => ({
    icons: [
      {
        icon: 'mdi-facebook',
        link: 'https://www.facebook.com/people/Wiki-Security-INC/100064205460242/',
        color: 'indigo'
      },
      {
        icon: 'mdi-youtube',
        link: 'https://www.youtube.com/channel/UCddHTMigvEFbI8Zu29ODwAQ?view_as=subscriber',
        color: 'red'
      },
      {
        icon: 'mdi-linkedin',
        link: 'https://in.linkedin.com/company/wiki-security',
        color: 'blue'
      },
    ],
    items: [
      { title: 'INTRODUCTION', icon: 'mdi-information', href: '/'},
      { title: 'LOGIN', icon: 'mdi-login', href: '/login', show: true },
      { title: 'SCANNER', icon: 'mdi-video', href: '/scan', show: false }, 
      { title: 'SCAN HISTORY', icon: 'mdi-history', href: '/history', show: false }
    ],
    show:false,
    // logoutTimer: null,
  }),
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    filteredItems() {
      if (this.isLogged) {
        return this.items.filter(item => !item.show || item.href == '/');
      } else {
        return this.items.filter(item => item.show || item.href == '/');
      }
    },
    userData(){
      return this.$store.state.userData;
    }
  },
  methods: {
    logout() {
      axios.post(`${this.$apiUrl}/user/logout`, {}, {withCredentials: true}).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch('logout').then(() => {
            this.$router.push('/login');
            // localStorage.removeItem('sessionExpiration');
            // if (this.$route.path !== '/login') {
            //   this.$router.push('/login');
            // } 
          });
        }
      }).catch(error => {
        console.error("Logout error: ", error);
      });
    },
    // startLogoutTimer() {
    //   const expirationTime = new Date().getTime() + 3600000;
    //   localStorage.setItem('sessionExpiration', expirationTime);
    //   if (this.logoutTimer) clearTimeout(this.logoutTimer);
    //   this.logoutTimer = setTimeout(() => {
    //     this.logout();
    //   }, 3600000);
    // },
  },
  // watch: {
  //   isLogged(newVal) {
  //     if (newVal) {
  //       this.startLogoutTimer();
  //     } else if (this.logoutTimer) {
  //       clearTimeout(this.logoutTimer);
  //     }
  //   }
  // },
    // created() {
    //   const sessionExpiration = localStorage.getItem('sessionExpiration');
    //   const currentTime = new Date().getTime();
    //   if (sessionExpiration && currentTime > sessionExpiration) {
    //     this.logout();
    //   } else if (sessionExpiration && currentTime <= sessionExpiration) {
    //     const timeLeft = sessionExpiration - currentTime;
    //     if (this.logoutTimer) clearTimeout(this.logoutTimer);
    //     this.logoutTimer = setTimeout(() => {
    //       this.logout();
    //     }, timeLeft);
    //   }
    // },
    // beforeDestroy() {
    //   if (this.logoutTimer) clearTimeout(this.logoutTimer);
    // }
}
</script>

<style>
.v-toolbar__content {
  height: 48px;
  position: relative;
}
#main-bar {
  left: 0 !important;
}
.home-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: auto !important;
}

</style>
