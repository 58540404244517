<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="historyList"
      sort-by=""
      class="elevation-0"
      :search="search"
    >
      <template v-slot:[`item.start_time`]="{ item }">
        {{ formatDateTime(item.start_time) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="font-weight-bold"
            >Scan History
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div style="width: 450px">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top nudge-top="-10" tag="div">
          <template v-slot:activator="{ on, attrs }">
            <img
              @click="showItem(item)"
              src="@/assets/file-find.svg"
              class="btn"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <div class="text-caption" style="width: fit-content">View</div>
        </v-tooltip>
        <v-tooltip top nudge-top="-10" tag="div">
          <template v-slot:activator="{ on, attrs }">
            <img
              @click="downloadItem(item)"
              src="@/assets/download.svg"
              class="btn"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <div class="text-caption">Download</div>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-dialog v-model="dialog" v-if="selectedItem" width="70%">
        <scan-result-vue
          :vulnLevel1="vulnLevel"
          :scanurl="scanUrl"
          :result="alerts"
          :show-close-button="true"
          @close="dialog = false"
          :is-history-page="true"
          :timeTaken="timeTaken"
          :findings="findings"
        ></scan-result-vue>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import scanResultVue from "./scanResult.vue";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import { address } from "@/mixins/api";

export default {
  name: "ScanHistory",
  components: {
    scanResultVue,
  },
  data: () => ({
    alerts: [],
    scanResult: true,
    scanUrl: null,
    search: "",
    timeTaken: null,
    findings: 0,
    totalVulns: 0,
    dialog: false,
    headers: [
      { text: "ID", value: "no", align: "center" },
      { text: "Start Time", value: "start_time" },
      { text: "URL", value: "scan_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    historyList: [],
    vulnLevel: [
      { level: "High", color: "error", value: 0 },
      { level: "Medium", color: "orange accent-2", value: 0 },
      { level: "Low", color: "yellow lighten-2", value: 0 },
      { level: "Info", color: "blue accent-1", value: 0 },
      { level: "Malware", color: "grey lighten-1", value: 0 },
      { level: "Yara", color: "brown lighten-1", value: 0 },
    ],
    selectedItem: null,
  }),
  created() {
    this.fetchHistory();
  },
  methods: {
    async fetchHistory() {
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/history`,
          {},
          { withCredentials: true }
        );
        this.historyList = response.data
          .map((item, index) => ({
            ...item,
            no: index + 1,
          }))
          .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
      } catch (error) {
        console.error("Error fetching scan history:", error);
      }
    },
    async scanAlert(scanId) {
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/scan/alerts`,
          { scan_id: scanId },
          { withCredentials: true }
        );
        console.log("API Response for scanAlert:", response.data);

        this.alerts = response.data;
        if (!Array.isArray(this.alerts)) {
          throw new Error("Expected alerts to be an array");
        }

        let highCnt = 0,
          medCnt = 0,
          lowCnt = 0,
          infoCnt = 0,
          malCnt = 0;

        this.alerts.forEach((alert) => {
          switch (alert.risk) {
            case "High":
              highCnt++;
              break;
            case "Medium":
              medCnt++;
              break;
            case "Low":
              lowCnt++;
              break;
            case "Information":
              infoCnt++;
              break;
            case "Malware":
              malCnt++;
              break;
            case "Yara":
              malCnt++;
              break;
          }
          this.findings = highCnt + medCnt + lowCnt + infoCnt + malCnt;
        });
        this.findings = highCnt + medCnt + lowCnt + infoCnt + malCnt;
        this.vulnLevel = this.vulnLevel.map((level) => {
          switch (level.level) {
            case "High":
              return { ...level, value: highCnt };
            case "Medium":
              return { ...level, value: medCnt };
            case "Low":
              return { ...level, value: lowCnt };
            case "Information":
              return { ...level, value: infoCnt };
            case "Malware":
              return { ...level, value: malCnt };
            case "Yara":
              return { ...level, value: malCnt };
            default:
              return level;
          }
        });

        this.scanResult = true;
        console.log("Scan results fetched successfully");
      } catch (error) {
        console.error("Error fetching scan alerts:", error);
        this.vulnLevel.forEach((level) => (level.value = 0));
      }
    },

    showItem(item) {
      console.log(item);
      this.selectedItem = item;
      this.scanUrl = item.scan_url;
      this.alerts = [];
      this.dialog = true;
      this.findings = 0;
      if (item.start_time && item.end_time && item.end_time > item.start_time) {
        this.timeTaken = this.calculateDuration(
          new Date(item.start_time),
          new Date(item.end_time)
        );
      } else {
        this.timeTaken = "0";
      }
      this.scanAlert(item.id);
    },

    getAlertColor(risk) {
      switch (risk) {
        case "High":
          return "#FF4C4C";
        case "Medium":
          return "#FFA500";
        case "Low":
          return "#FFFF00";
        case "Informational":
          return "#87CEEB";
        case "Information":
          return "#87CEEB";
        case "Malware":
          return "#808080";
        case "Yara":
          return "#8D6E63";
        case "Crypto":
          return "#8D6E63";
        default:
          return "#FFFFFF";
      }
    },
    addHeader(doc, itemId, formattedStartTime) {
      const headerText = `Wikisecurity Website Scanner\nTest ID: ${itemId}\nDate and time: ${formattedStartTime}`;
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(headerText, 14, 15);
      doc.setDrawColor(0, 0, 0);
      doc.line(14, 26, doc.internal.pageSize.width - 14, 26);
    },

    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleDateString("en-US", options);
    },
    calculateDuration(startTime, endTime) {
      const timeDiff = new Date(endTime - startTime);
      const hours = String(timeDiff.getUTCHours()).padStart(2, "0");
      const minutes = String(timeDiff.getUTCMinutes()).padStart(2, "0");
      const seconds = String(timeDiff.getUTCSeconds()).padStart(2, "0");
      this.timeTaken = `${hours}:${minutes}:${seconds}`;
      return this.timeTaken;
    },

    cryptoDesc: function(desc) {
      try {
        let desc1 = desc.replace(/'/g, '"');
        desc1 = desc1.replace(/(\[|\s)([A-Za-z_][A-Za-z0-9_]*)/g, '$1"$2"');
        desc1 = desc1.replace(/"does"/g, 'does');
        const descArr = JSON.parse(desc1);
        const matchArr = descArr.map(i => i.match_list[0]);
        const str = matchArr.join('\n');
        return str;
      } catch (error) {
        console.error('Error parsing description:', error);
      }
    },


    async downloadItem(item) {
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/scan/alerts`,
          { scan_id: item.id },
          { withCredentials: true }
        );
        const alerts = response.data;
        const doc = new jsPDF();
        const formattedStartTime = this.formatDateTime(item.start_time);
        const finishTime = this.formatDateTime(item.end_time);
        const duration = this.calculateDuration(
          new Date(item.start_time),
          new Date(item.end_time)
        );
        const groupedAlerts = {};
        alerts.forEach((alert) => {
          const key = `${alert.risk}-${alert.alert}`;
          if (!groupedAlerts[key]) {
            groupedAlerts[key] = {
              ...alert,
              count: 1,
              instances: [
                { url: alert.url, method: alert.method, param: alert.param, description: alert.description },
              ],
            };
          } else {
            groupedAlerts[key].count += 1;
            groupedAlerts[key].instances.push({
              url: alert.url,
              method: alert.method,
              param: alert.param,
              description: alert.description
            });
          }
        });
        let totalPages;
        let currentPage = 1;
        let detailedReportAdded = false;
        const addHeaderIfNeeded = (pageNumber) => {
          if (pageNumber <= 2 || (totalPages && pageNumber === totalPages)) {
            this.addHeader(doc, item.id, formattedStartTime);
          }
        };
        addHeaderIfNeeded(currentPage);
        const tableRows = [];
        this.totalVulns = 0;
        Object.values(groupedAlerts).forEach((alert) => {
          const riskColor = this.getAlertColor(alert.risk);
          const cleanAlertName = alert.alert ? alert.alert.replace(/<br>/g, ' ') : '';
          if (alert.risk === "Malware") {
            const validInstanceCount = alert.instances.filter(instance => instance.description 
            && instance.description !== 'no search malware at Url\n' && instance.description !== 'Connection failed please check url\n').length;
            const customMessage = `Malware was found in ${validInstanceCount} of ${alert.count} resources`;
            this.totalVulns += validInstanceCount;
            tableRows.push([
              { content: alert.risk, styles: { fillColor: riskColor } },
              customMessage,
              validInstanceCount,
            ]);
          } else if(alert.risk === "Informational"){
            this.totalVulns += alert.count;
            tableRows.push([
              { content: alert.risk = 'Information', styles: { fillColor: riskColor } },
              cleanAlertName,
              alert.count,
            ]);
          } else if(alert.risk === "Yara"){
            const validInstanceCount = alert.instances.filter(instance => instance.description 
            && instance.description !== 'It does not match the yara rule' && instance.description !== 'Connection failed please check url\n').length;
            const customMessage = `Yara was found in ${validInstanceCount} of ${alert.count} resources`;
            this.totalVulns += validInstanceCount;
            tableRows.push([
              { content: alert.risk = "Yara", styles: { fillColor: riskColor } },
              customMessage,
              validInstanceCount,
            ]);
          }
          else {
            this.totalVulns += alert.count;
            tableRows.push([
              { content: alert.risk, styles: { fillColor: riskColor } },
              cleanAlertName,
              alert.count,
            ]);
          }
        });
        doc.setFontSize(18);
        doc.setFont("helvetica", "bold");
        doc.text("WikiWebScanner Report", 14, 45);
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Summary", 14, 60);
        const leftX = 14;
        const rightX = 90;
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        const lineSpacing = 7;
        doc.text("Target Site:", leftX, 70);
        doc.setFont("helvetica", "normal");
        doc.text(`${item.scan_url}`, rightX, 70);
        doc.setFont("helvetica", "bold");
        doc.text("Start Date/Time:", leftX, 70 + lineSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(`${formattedStartTime}`, rightX, 70 + lineSpacing);
        doc.setFont("helvetica", "bold");
        doc.text("Finish Date/Time:", leftX, 70 + 2 * lineSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(`${finishTime}`, rightX, 70 + 2 * lineSpacing);
        doc.setFont("helvetica", "bold");
        doc.text("Duration:", leftX, 70 + 3 * lineSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(`${duration}`, rightX, 70 + 3 * lineSpacing);
        doc.setFont("helvetica", "bold");
        doc.text("Report Generated on:", leftX, 70 + 4 * lineSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(`${finishTime}`, rightX, 70 + 4 * lineSpacing);
        doc.setFont("helvetica", "bold");
        doc.text("No. of Vulnerabilities Found:", leftX, 70 + 5 * lineSpacing);
        doc.setFont("helvetica", "normal");
        doc.text(`${this.totalVulns}`, rightX, 70 + 5 * lineSpacing);
        
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Vulnerabilities List", 14, 120);

        const riskOrder = ["High", "Medium", "Low", "Information", "Malware", "Yara"];
        tableRows.sort((a, b) => {
          return riskOrder.indexOf(a[0].content) - riskOrder.indexOf(b[0].content);
        });
        doc.autoTable({
          startY: 125,
          head: [["Risk Level", "Vulnerability Name", "Instances"]],
          body: tableRows,
          styles: {
            lineWidth: 0,
            cellPadding: 4,
            fontSize: 10,
            valign: "middle",
            halign: "left",
          },
          headStyles: {
            textColor: [0, 0, 0],
            fontStyle: "bold",
            lineWidth: 0.5,
            lineColor: "black",
            fillColor: null,
          },
          bodyStyles: {
            fillColor: null,
            lineWidth: 0.5,
            textColor: "black",
            lineColor: "black"
          },
        });
        console.log(groupedAlerts);
        const sortedAlerts = Object.values(groupedAlerts).sort((a, b) => {
          return riskOrder.indexOf(a.risk) - riskOrder.indexOf(b.risk);
        });
        sortedAlerts.forEach((alert) => {
          doc.addPage();
          currentPage++;
          let topMargin = 20;
          addHeaderIfNeeded(currentPage);
          if (!detailedReportAdded && currentPage === 2) {
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text("Detailed Report", 14, 42);
            detailedReportAdded = true;
            topMargin = 40;
          }

          let mergedBody;

          if (alert.risk === "Malware") {
            const validInstanceCount = alert.instances.filter(instance => instance.description && 
            instance.description !== 'no search malware at Url\n' && 
            instance.description !== null &&  instance.description !== ''  && instance.description !== 'Connection failed please check url\n').length;
            const customMessage = `Malware was found in ${validInstanceCount} of ${alert.count} resources`;
            const sortedInstances = alert.instances.sort((a, b) => {
              const isValidA = a.description && a.description !== 'no search malware at Url\n';
              const isValidB = b.description && b.description !== 'no search malware at Url\n';
              return isValidA === isValidB ? 0 : isValidA ? -1 : 1;
            });
            mergedBody = [
              [
                {
                  content: `${customMessage} | Instances: ${validInstanceCount}`,
                  colSpan: 2,
                  styles: {
                    fillColor: this.getAlertColor(alert.risk),
                    textColor: [0, 0, 0],
                    fontStyle: "bold",
                    halign: "left",
                  },
                },
              ],
            ];
            sortedInstances.forEach((instance) => {
              const isValidDescription = instance.description && instance.description !== 'no search malware at Url\n'  && instance.description !== 'Connection failed please check url\n';
              mergedBody.push(
                [
                  {
                    content: isValidDescription ? "Instance" : "",
                    styles: {
                      fontStyle: "bold",
                      valign: "middle",
                      halign: "center",
                      textColor: [0, 0, 0],
                      fontSize: 12,
                    },
                    rowSpan: 2,
                  },
                  {
                    content: `URL: ${instance.url || "N/A"}`,
                    styles: {
                      halign: "left",
                      valign: "middle",
                      textColor: [0, 0, 0],
                    },
                  },
                ],
                [
                  {
                    content: `DESCRIPTION: ${instance.description || "N/A"}`,
                    styles: {
                      halign: "left",
                      valign: "middle",
                      textColor: [0, 0, 0],
                    },
                  },
                  ""
                ]
              );
            });
          } else if (alert.risk === "Yara") {
              const sortedInstances = alert.instances.sort((a, b) => {
                const isValidA = a.description && a.description !== 'It does not match the yara rule';
                const isValidB = b.description && b.description !== 'It does not match the yara rule';
                return isValidA === isValidB ? 0 : isValidA ? -1 : 1;
              });
              const validInstanceCount = alert.instances.filter(instance => instance.description 
              && instance.description !== 'It does not match the yara rule' && instance.description !== 'Connection failed please check url\n').length;
              mergedBody = [
                [
                  {
                    content: `Yara was found in ${validInstanceCount} of ${alert.count} resources | Instances: ${validInstanceCount}`,
                    colSpan: 2,
                    styles: {
                      fillColor: this.getAlertColor(alert.risk),
                      textColor: [0, 0, 0],
                      fontStyle: "bold",
                      halign: "left",
                    },
                  },
                ],
              ];
              sortedInstances.forEach((instance) => {
                const isValidDescription = instance.description && instance.description !== 'It does not match the yara rule'  && instance.description !== 'Connection failed please check url\n';
                mergedBody.push(
                  [
                    {
                      content: isValidDescription ? "Instance" : '',
                      styles: {
                        fontStyle: "bold",
                        valign: "middle",
                        halign: "center",
                        textColor: [0, 0, 0],
                        fontSize: 12,
                      },
                      rowSpan: 2,
                    },
                    {
                      content: `URL: ${instance.url || "N/A"}`,
                      styles: {
                        halign: "left",
                        valign: "middle",
                        textColor: [0, 0, 0],
                      },
                    },
                  ],
                  [
                    {
                      content: isValidDescription ? `DESCRIPTION: ${this.cryptoDesc(instance.description)}` : "DESCRIPTION: It does not match the yara rule",
                      styles: {
                        halign: "left",
                        valign: "middle",
                        textColor: [0, 0, 0],
                      },
                    },
                    ""
                  ]
                );
            })}
          else {
            const cleanAlertName = alert.alert ? alert.alert.replace(/<br>/g, ' ') : '';
            mergedBody = [
              [
                {
                  content: `${cleanAlertName} | Instances ${alert.count}`,
                  colSpan: 2,
                  styles: {
                    fillColor: this.getAlertColor(alert.risk),
                    textColor: [0, 0, 0],
                    fontStyle: "bold",
                    halign: "left",
                  },
                },
              ],
              [
                {
                  content: "Description:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.description || "No description available",
              ],
              [
                {
                  content: "Solution:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.solution || "No solution provided",
              ],
              [
                {
                  content: "Other Information:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.other || "",
              ],
              [
                {
                  content: "References:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.reference || "No references available",
              ],
              [
                {
                  content: "CWE ID:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.cweid || "0",
              ],
              [
                {
                  content: "WASC ID:",
                  styles: { fontStyle: "bold", textColor: [0, 0, 0] },
                },
                alert.wascid || "0",
              ],
            ];

            alert.instances.forEach((instance) => {
              mergedBody.push(
                [
                  {
                    content: "Instance",
                    styles: {
                      fontStyle: "bold",
                      valign: "middle",
                      halign: "center",
                      textColor: [0, 0, 0],
                      fontSize: 12,
                    },
                    rowSpan: 3,
                  },
                  {
                    content: `METHOD: ${instance.method || "N/A"}`,
                    styles: {
                      halign: "left",
                      valign: "middle",
                      textColor: [0, 0, 0],
                    },
                  },
                ],
                [
                  {
                    content: `URL: ${instance.url || "N/A"}`,
                    styles: {
                      halign: "left",
                      valign: "middle",
                      textColor: [0, 0, 0],
                    },
                  },
                  ""
                ],
                [
                  {
                    content: `PARAM: ${instance.param || "N/A"}`,
                    styles: {
                      halign: "left",
                      valign: "middle",
                      textColor: [0, 0, 0],
                    },
                  },
                  ""
                ]
              );
            });
          }

          doc.autoTable({
            startY: topMargin + 10,
            body: mergedBody,
            theme: "grid",
            styles: {
              lineWidth: 0.5,
              lineColor: [0, 0, 0],
              cellPadding: 4,
              fontSize: 10,
            },
            columnStyles: {
              0: { fontStyle: "bold", cellWidth: 40 },
              1: { cellWidth: "auto" },
            },
            margin: { right: 20 },
          });
        });

        doc.addPage();
        currentPage++;
        this.addHeader(doc, item.id, formattedStartTime);
        doc.setFontSize(20);
        doc.setFont("helvetica", "bold");
        const thankYouMessage = "Thank you for scanning with WikiWebScanner!";
        const centerX = doc.internal.pageSize.width / 2;
        doc.text(thankYouMessage, centerX, 60, { align: "center" });
        totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          if (i <= 2 || i === totalPages) {
            this.addHeader(doc, item.id, formattedStartTime);
          }
          doc.setFontSize(10);
          doc.text(
            `Page ${i} of ${totalPages}`,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );
        }
        doc.save(`scan_report_${item.id}.pdf`);
      } catch (error) {
        console.error("Error downloading scan report:", error);
      }
    },
  },
};
</script>

<style scoped>
.btn {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  filter: invert(66%) sepia(8%) saturate(576%) hue-rotate(155deg)
    brightness(95%) contrast(94%);
}
.v-tooltip--top {
  width: fit-content;
  padding: 0px !important;
}
</style>
