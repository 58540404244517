<template>
    <v-card class="pa-3" elevation="0">
        <canvas ref="barChart" style="width: 100%;"></canvas>
    </v-card>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export default {
    data: () => ({
        chart: null,
        type: 'bar',
        data: {
            labels: ['High', 'Medium', 'Low', 'Info', 'Malware', 'Yara'],
            datasets: [{
                label: '',
                data: [],
                backgroundColor: ['#F44336', '#FFAB40', '#FFF176', '#82B1FF', '#BDBDBD', '#8D6E63']
            }],
        },
        options: {
            maintainAspectRatio: false,
            indexAxis: 'y',
            plugins: {
                legend: {
                    display: false
                },
                datalabels: {
                    align: 'end',
                    color: '#616161',
                    font: {
                        weight: 'normal',
                        size: 14
                    },
                    formatter: function(value, context) {
                        return "- " + context.chart.data.labels[context.dataIndex] + ": " + value;
                    }
                },
                tooltip: {
                    padding: 9,
                    backgroundColor: '#FFFFFF',
                    titleColor: '#000000',
                    titleFont: {
                        size: 13,
                        weight: 'bold'
                    },
                    titleAlign: 'center',
                    bodyColor: '#757575',
                    bodyFont: {
                        size: 12,
                        weight: 'bold'
                    },
                    borderColor: '#E0E0E0',
                    borderWidth: 1,
                    displayColors: false,
                    callbacks: {
                        label: function(context) {
                            return "Count: " + context.raw;
                        }
                    }
                }
            },
            scales: {
                y: {
                    grid: {
                        display: false
                    }
                },
                x: {
                    grid: {
                        color: (ctx) => (ctx.index === 0 ? '#757575' : ctx.index % 2 == 1 ? '#E0E0E0' : '#BDBDBD')
                    }
                },
            }
        },
        plugins: [ChartDataLabels]
    }),
    mounted() {
        this.createChart();
    },
    methods: {
        createChart() {
            const ctx = this.$refs.barChart ? this.$refs.barChart.getContext('2d') : null;
            if (!ctx) {
                console.error("Failed to acquire canvas context.");
                return;
            }
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: this.data,
                options: this.options
            });
        },
        updateChart(newData) {
            if (this.chart && Array.isArray(newData) && newData.every(item => typeof item === 'number')) {
                this.chart.data.datasets[0].data = newData;
                this.chart.update();
                console.log("Chart initialized")
            } else if (this.chart) {
                console.warn("Chart data is invalid, resetting to default values.");
                this.chart.data.datasets[0].data = [0, 0, 0, 0, 0, 0];
                this.chart.update();
            } else {
                console.warn("Chart is not initialized yet.");
            }
        },
        resetChartData() {
            if (this.chart) {
                this.chart.data.datasets[0].data = [0, 0, 0, 0, 0, 0];
                this.chart.update();
            }
        }
    },
    props: {
        vulnLevel1: {
            type: Array,
            default: () => [],
        }
    },
    watch: {
        vulnLevel1: {
            handler(newVal) {
                if (Array.isArray(newVal) && newVal.length && newVal.every(x => typeof x.value === 'number')) {
                    const newData = newVal.map(x => x.value);
                    this.updateChart(newData);
                } else if (newVal.length === 0) {
                    this.resetChartData();
                    console.warn("Scan completed but no data available, resetting chart.");
                } else {
                    console.warn("Invalid data received for chart.");
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
